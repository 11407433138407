import React from "react";
import { Flex, Text, Switch, Select } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const AlgorithmItem = ({
  itemName = "",
  itemValue = "",
  itemCallback = () => {},
  showSelect = true,
}) => {
  const { t } = useTranslation();

  return (
    <Flex
      key={itemName}
      align="center"
      border="1px solid #e2e8f0"
      borderRadius={5}
      p={4}
      mt={6}
      justify="space-between"
      height="70px"
    >
      <Text fontWeight="medium">{itemName}</Text>
      <Flex gap={2} align="center">
        {showSelect && (
          <Select
            isDisabled={itemValue === 101}
            size="sm"
            minW={120}
            value={itemValue}
            onChange={(e) => {
              itemCallback(e.target.value);
            }}
          >
            <option value={90}>{t("LOW")}</option>
            <option value={80}>{t("MIDDLE")}</option>
            <option value={70}>{t("HIGH")}</option>
          </Select>
        )}
        <Switch
          ml={4}
          onChange={(e) => {
            if (showSelect) {
              itemCallback(e.currentTarget.checked ? 70 : 101);
            } else {
              itemCallback(e.currentTarget.checked ? true : false);
            }
          }}
          defaultChecked={showSelect ? itemValue !== 101 : itemValue}
        />
      </Flex>
    </Flex>
  );
};

export default AlgorithmItem;
