import React from "react";

import { Box, Flex, Skeleton, Spacer } from "@chakra-ui/react";

import CorrectionProgressSkeleton from "components/CorrectionProgressSkeleton";

const EvaluationScreenSkeleton = () => {
  return (
    <Flex justifyContent="space-between" w="100%">
      <Flex flexDirection="column" w="100%" pl="16" overflow="hidden">
        <Skeleton w="200px" h="10" />
        <Flex mt="4" mb="1" w="100%">
          <Skeleton w="420px" h="4" />
        </Flex>
        <Skeleton mt="2" height="2px" borderRadius="100px" />
        <Flex h="50px" justifyContent="flex-start" alignItems="center" mb="1">
          <Skeleton mt="6" w="220px" h="6" borderRadius="10px"></Skeleton>
        </Flex>
        <Box h="50px" justifyContent="flex-start" alignItems="center" my="4">
          <Skeleton mt="6" w="100px" h="8" borderRadius="10px" />
          <Skeleton w="100px" h="4" borderRadius="10px" mt="4" />
        </Box>
        <Skeleton mt="12" w="100%" h="8" p="4"></Skeleton>
        <Spacer maxH="80px" />
        <Skeleton w="100px" h="4" borderRadius="10px" mt="12" />
        <Skeleton mt="2" w="160px" h="8" p="4" borderRadius="15px"></Skeleton>
        <Spacer maxH="60px" />
        <Skeleton w="100px" h="4" borderRadius="10px" mt="12" />
        <Skeleton mt="2" w="190px" h="10" p="4"></Skeleton>
        <Skeleton mt="2" w="190px" h="2"></Skeleton>
        <Flex justifyContent="space-between" mt="5">
          <Skeleton w="100px" h="10" borderRadius="10px" mt="4" />
          <Skeleton w="100px" h="10" borderRadius="10px" mt="4" />
        </Flex>
      </Flex>

      <Spacer />
      <Flex
        ml="4"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Skeleton w="230px" h="10" mb="4" />
        <CorrectionProgressSkeleton />
      </Flex>
    </Flex>
  );
};

export default EvaluationScreenSkeleton;
