import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { post } from "../utils/fetch";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import ContainerAuth from "components/ContainerAuth";
import HidePasswordButton from "components/HidePasswordButton";

export default function ResetPassword() {
  const { uid, token } = useParams();
  const [passwordReset, setPasswordReset] = useState(false);
  const history = useHistory();
  const [loadingReset, setLoadingReset] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const { t } = useTranslation();
  const toast = useToast();

  async function resetPassword(e) {
    e.preventDefault();
    try {
      setLoadingReset(true);
      await post(
        "/api/auth/password_reset/confirm/",
        {
          password: newPassword,
          password_confirm: confirmNewPassword,
          uid,
          token,
        },
        "POST"
      );
      setPasswordReset(true);
      setLoadingReset(false);
      setTimeout(() => {
        history.push("/login");
      }, 5000);
    } catch (e) {
      console.error(e);
      setLoadingReset(false);
      toast({
        description: t("FEEDBACKS.RESET_PASSWORD"),
        duration: 3000,
        status: "error",
        isClosable: true,
        position: "top",
      });
    }
  }

  const form = (
    <>
      <FormControl>
        <FormLabel for="new_password">{t("NEW_PASSWORD")}</FormLabel>
        <InputGroup>
          <Input
            required
            type={showNewPassword ? "text" : "password"}
            name="new_password"
            placeholder="********"
            onChange={(e) => setNewPassword(e.currentTarget.value)}
          />
          <HidePasswordButton
            showPassword={showNewPassword}
            setShowPassword={setShowNewPassword}
          />
        </InputGroup>
      </FormControl>
      <FormControl
        isInvalid={
          newPassword &&
          confirmNewPassword &&
          newPassword !== confirmNewPassword
        }
      >
        <FormLabel for="confirm_password">
          {t("CONFIRM_NEW_PASSWORD")}
        </FormLabel>
        <InputGroup>
          <Input
            required
            type={showConfirmNewPassword ? "text" : "password"}
            name="confirm_new_password"
            placeholder="********"
            onChange={(e) => setConfirmNewPassword(e.currentTarget.value)}
          />
          <HidePasswordButton
            showPassword={showConfirmNewPassword}
            setShowPassword={setShowConfirmNewPassword}
          />
        </InputGroup>
        <FormErrorMessage>
          {t("FEEDBACKS.CONFIRM_PASSWORD_ERROR")}
        </FormErrorMessage>
      </FormControl>
      <Button
        onClick={resetPassword}
        isLoading={loadingReset}
        colorScheme="red"
        disabled={
          !newPassword ||
          !confirmNewPassword ||
          newPassword !== confirmNewPassword
        }
        w="100%"
      >
        {t("CHANGE_PASSWORD")}
      </Button>
    </>
  );

  const message = <Text>{t("FEEDBACKS.PASSWORD_CHANGED")}</Text>;

  return <ContainerAuth>{passwordReset ? message : form}</ContainerAuth>;
}
