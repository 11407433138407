import { EditIcon } from "@chakra-ui/icons";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { changePassword } from "service/SettingsViewServices";

function ChangePasswordModal() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const toast = useToast();
  const {
    isOpen: changePasswordIsOpen,
    onOpen: changePasswordOnOpen,
    onClose: changePasswordOnClose,
  } = useDisclosure();

  const handleChangePassword = async () => {
    setLoading(true);
    try {
      await changePassword(oldPassword, newPassword);
      changePasswordOnClose();
      setLoading(false);
      toast({
        title: t("FEEDBACKS.CHANGE_PASSWORD"),
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      setLoading(false);
      toast({
        title: t(`FEEDBACKS.${error.message}`),
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  };

  return (
    <>
      <IconButton
        variant="ghost"
        aria-label={t("EDIT")}
        icon={<EditIcon />}
        h="1.75rem"
        colorScheme="blue"
        size="sm"
        onClick={changePasswordOnOpen}
      />
      <Modal isOpen={changePasswordIsOpen} onClose={changePasswordOnClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("CHANGE_PASSWORD")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb={4}>
              <FormLabel for="old_password">{t("OLD_PASSWORD")}</FormLabel>
              <InputGroup>
                <Input
                  required
                  type={"password"}
                  name="old_password"
                  placeholder="********"
                  onChange={(e) => setOldPassword(e.currentTarget.value)}
                />
              </InputGroup>
            </FormControl>
            <FormControl mb={4}>
              <FormLabel for="new_password">{t("NEW_PASSWORD")}</FormLabel>
              <InputGroup>
                <Input
                  required
                  type={"password"}
                  name="new_password"
                  placeholder="********"
                  onChange={(e) => setNewPassword(e.currentTarget.value)}
                />
              </InputGroup>
            </FormControl>
            <FormControl
              isInvalid={
                newPassword &&
                confirmNewPassword &&
                newPassword !== confirmNewPassword
              }
            >
              <FormLabel for="confirm_password">
                {t("CONFIRM_NEW_PASSWORD")}
              </FormLabel>
              <InputGroup>
                <Input
                  required
                  type={"password"}
                  name="confirm_new_password"
                  placeholder="********"
                  onChange={(e) => setConfirmNewPassword(e.currentTarget.value)}
                />
              </InputGroup>
              <FormErrorMessage>
                {t("FEEDBACKS.CONFIRM_PASSWORD_ERROR")}
              </FormErrorMessage>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} colorScheme="gray" onClick={changePasswordOnClose}>
              {t("CANCEL")}
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleChangePassword}
              isDisabled={
                !oldPassword ||
                !newPassword ||
                !confirmNewPassword ||
                newPassword !== confirmNewPassword
              }
              isLoading={loading}
            >
              {t("SAVE")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ChangePasswordModal;
