import React from "react";
import { Container } from "@chakra-ui/react";

const ContainerLayout = (props) => {
  return (
    <Container maxWidth="100%" padding="10" paddingTop="100px" centerContent>
      {props.children}
    </Container>
  );
};

export default ContainerLayout;
