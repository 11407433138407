import {
  Box,
  Flex,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
} from "@chakra-ui/react";

import RichEditorComponent from "components/Editor/RichEditorComponent";
import { v4 as uuidv4 } from "uuid";
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";

import { useCallback, useContext, useEffect, useState } from "react";

import useDebounce from "utils/useDebounce";

import { EvaluationContext } from "./MyEvaluationContext";
import { useTranslation } from "react-i18next";

const EditorPanel = ({ setChoicesFeedback, selected, choicesFeedback }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const debouncedEditorState = useDebounce(editorState, 500);

  useEffect(() => {
    setChoicesFeedback((feedbacks) =>
      feedbacks.map((f, i) => {
        if (i === selected) {
          if (debouncedEditorState.getCurrentContent().getPlainText().trim()) {
            return stateToHTML(debouncedEditorState.getCurrentContent());
          }
          return "";
        }
        return f;
      })
    );
  }, [debouncedEditorState]);

  useEffect(() => {
    const html = choicesFeedback[selected] ?? "";
    const state = EditorState.createWithContent(stateFromHTML(html));
    setEditorState(state);
  }, [selected]);

  return (
    <RichEditorComponent
      editorState={editorState}
      setEditorState={setEditorState}
    />
  );
};

const MyEvaluationChoiceComments = () => {
  const { evaluation, setChoicesFeedback, choicesFeedback } =
    useContext(EvaluationContext);
  const [selected, setSelected] = useState(0);
  const { t } = useTranslation();
  const onClickChoice = useCallback((index) => {
    setSelected(index);
  }, []);
  return (
    <Flex flexDirection="column" width="100%">
      <Text my="2" fontSize="lg">
        {t("JUSTIFY_ALTERNATIVES")}
      </Text>
      <Flex borderWidth="1px" borderColor="gray.200" borderRadius="8px">
        <Tabs
          m="2"
          w="100%"
          size="md"
          variant="enclosed"
          onChange={onClickChoice}
        >
          <TabList>
            {evaluation.possible_choices.map((_, i) => (
              <Tab key={uuidv4()}>
                {String.fromCharCode(0x61 + i)?.toUpperCase()}
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            {evaluation.possible_choices.map((_, i) => (
              <TabPanel p="0" pt="2" key={i}>
                <EditorPanel
                  choicesFeedback={choicesFeedback}
                  setChoicesFeedback={setChoicesFeedback}
                  selected={selected}
                />
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Flex>
      {choicesFeedback.map(
        (f, i) =>
          f && (
            <Box mt="4" key={i}>
              <Box fontSize="sm">
                <Box display="inline-block" marginEnd="2">
                  {String.fromCharCode(0x61 + i)}
                </Box>
                {evaluation.possible_choices[i]}
              </Box>
              <Box
                backgroundColor="blue.50"
                borderRadius="md"
                color="blue.500"
                paddingX="4"
                paddingY="2"
                marginX="4"
                marginY="2"
                dangerouslySetInnerHTML={{ __html: f }}
              />
            </Box>
          )
      )}
    </Flex>
  );
};

export default MyEvaluationChoiceComments;
