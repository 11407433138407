// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `tbody {
  display: block;
  max-height: 200px;
  overflow: auto;
}

thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  /* even columns width , fix width of table too*/
}

thead {
  width: calc(100% - 1em);
}

.tableContainer {
  border-radius: 10px;
  width: 100%;
  margin: 15px 0;
  padding: 20px;
  border: 1px solid #E2E8F0;
}
.tableContainer__table {
  width: 100%;
}
.tableContainer__table__header {
  text-align: justify;
  border-bottom: 1px solid #E2E8F0;
}
.tableContainer__table__header > th {
  color: #7E7E7E;
  font-size: 14px;
  padding-bottom: 15px;
  font-weight: 600;
}
.tableContainer__table__line > td {
  padding: 10px 0;
}
.tableContainer__table__line:not(:last-child) {
  border-bottom: 1px solid #E2E8F0;
}

.box {
  border-radius: 10px;
  padding: 20px;
  border: 1px solid #E2E8F0;
  min-width: 100%;
}

.title {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/MyEvaluation/styles.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,iBAAA;EACA,cAAA;AACJ;;AAEA;;EAEI,cAAA;EACA,WAAA;EACA,mBAAA;EACA,+CAAA;AACJ;;AAEA;EACI,uBAAA;AACJ;;AAGA;EACI,mBAAA;EACA,WAAA;EACA,cAAA;EACA,aAAA;EACA,yBAAA;AAAJ;AAEI;EACI,WAAA;AAAR;AAEQ;EACI,mBAAA;EACA,gCAAA;AAAZ;AAEY;EACI,cAAA;EACA,eAAA;EACA,oBAAA;EACA,gBAAA;AAAhB;AAKY;EACI,eAAA;AAHhB;AAMY;EACI,gCAAA;AAJhB;;AAUA;EACI,mBAAA;EACA,aAAA;EACA,yBAAA;EACA,eAAA;AAPJ;;AAUA;EACI,gBAAA;EACA,eAAA;EACA,mBAAA;AAPJ","sourcesContent":["tbody {\n    display: block;\n    max-height: 200px;\n    overflow: auto;\n}\n\nthead,\ntbody tr {\n    display: table;\n    width: 100%;\n    table-layout: fixed;\n    /* even columns width , fix width of table too*/\n}\n\nthead {\n    width: calc(100% - 1em)\n        /* scrollbar is average 1em/16px width, remove it from thead width */\n}\n\n.tableContainer {\n    border-radius: 10px;\n    width: 100%;\n    margin: 15px 0;\n    padding: 20px;\n    border: 1px solid #E2E8F0;\n\n    &__table {\n        width: 100%;\n\n        &__header {\n            text-align: justify;\n            border-bottom: 1px solid #E2E8F0;\n\n            &>th {\n                color: #7E7E7E;\n                font-size: 14px;\n                padding-bottom: 15px;\n                font-weight: 600;\n            }\n        }\n\n        &__line {\n            &>td {\n                padding: 10px 0;\n            }\n\n            &:not(:last-child) {\n                border-bottom: 1px solid #E2E8F0;\n            }\n        }\n    }\n}\n\n.box {\n    border-radius: 10px;\n    padding: 20px;\n    border: 1px solid #E2E8F0;\n    min-width: 100%;\n}\n\n.title {\n    font-weight: 600;\n    font-size: 18px;\n    margin-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
