import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import ContainerLayout from "components/ContainerLayout";
import { useTranslation } from "react-i18next";
import Tags from "@yaireo/tagify/dist/react.tagify";
import "@yaireo/tagify/dist/tagify.css";
import { FaRegEye } from "react-icons/fa6";
import { FiRefreshCcw, FiSave } from "react-icons/fi";
import { useEffect, useMemo, useRef, useState } from "react";
import { getEmail, previewEmail, updateEmail } from "service/EmailService";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const EditEmail = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const tagifyRef = useRef();
  const toast = useToast();
  const [email, setEmail] = useState("");
  const [numQuestions, setNumQuestions] = useState(0);
  const [preview, setPreview] = useState("");
  const [isModalPreviewOpen, setIsModalPreviewOpen] = useState(false);

  function transformTag(tagData) {
    tagData.style =
      "--tag-bg: #E9F8FE; --tag-text-color: #386BA6; --tag-hover: #E9F8FE;";
    return tagData;
  }

  const variables =
    window.localStorage.getItem("user-lang") === "pt"
      ? {
          STUDENT_NAME: `{"identifier":"STUDENT_NAME","text":"Nome do estudante","value":"Retorna nome do estudante","prefix":"@"}`,
          ACTIVITY_SCORE: `{"identifier":"ACTIVITY_SCORE","text":"Nota na atividade","value":"Retorna a nota do estudante na atividade","prefix":"@"}`,
          DELIVER_DATE: `{"identifier":"DELIVER_DATE","text":"Data de entrega","value":"Retorna a data de entrega da atividade","prefix":"@"}`,
          RELEASE_DATE: `{"identifier":"RELEASE_DATE","text":"Data da atividade","value":"Retorna a data da atividade","prefix":"@"}`,
        }
      : {
          STUDENT_NAME: `{"identifier":"STUDENT_NAME","text":"Student name","value":"Return student name","prefix":"@"}`,
          ACTIVITY_SCORE: `{"identifier":"ACTIVITY_SCORE","text":"Activity note","value":"Return activity note","prefix":"@"}`,
          DELIVER_DATE: `{"identifier":"DELIVER_DATE","text":"Delivery date","value":"Return delivery date","prefix":"@"}`,
          RELEASE_DATE: `{"identifier":"RELEASE_DATE","text":"Activity date","value":"Retorna a data da atividade","prefix":"@"}`,
        };

  function transformToVariablesToBack(text) {
    return text.replace(/\[\[{"identifier":"(.*?)".*?\]\]/g, "{$1}");
  }

  const generateDinamicMapVariableQuestions = (identifier) => {
    if (/QUESTION_\d+/.test(identifier)) {
      return `{"identifier":"${identifier}","text":"${t("QUESTION")} ${
        identifier.split("_")[1]
      }","value":"${t("RETURN_QUESTION")} ${
        identifier.split("_")[1]
      }","prefix":"@"}`;
    }
    return null;
  };

  function transformToVariablesToFront(text) {
    return text.replace(/\{(.*?)\}/g, (match, p1) => {
      const jsonObj = variables[p1] || generateDinamicMapVariableQuestions(p1);
      return jsonObj ? `[[${jsonObj}]]` : match;
    });
  }

  const tagList = useMemo(() => {
    const auxList = [
      {
        identifier: "STUDENT_NAME",
        text: t("STUDENT_NAME"),
        value: t("RETURN_STUDENT_NAME"),
      },
      {
        identifier: "DELIVER_DATE",
        text: t("DELIVERY_DATE"),
        value: t("RETURN_DELIVERY_DATE"),
      },
      {
        identifier: "RELEASE_DATE",
        text: t("ACTIVITY_DATE"),
        value: t("RETURN_ACTIVITY_DATE"),
      },
      {
        identifier: "ACTIVITY_SCORE",
        text: t("ACTIVITY_NOTE"),
        value: t("RETURN_ACTIVITY_NOTE"),
      },
    ];
    for (let num = 0; num < numQuestions; num++) {
      auxList.push({
        identifier: `QUESTION_${num + 1}`,
        text: `${t("QUESTION")} ${num + 1}`,
        value: `${t("RETURN_QUESTION")} ${num + 1}`,
      });
    }
    return auxList;
  }, [numQuestions]);

  const settings = {
    mode: "mix",
    pattern: "@",
    tagTextProp: "text",
    duplicates: true,
    whitelist: tagList,
    transformTag: (tagData) => transformTag(tagData),
    dropdown: {
      enabled: 0,
      position: "text",
      mapValueTo: "text",
      highlightFirst: true,
      maxItems: 30,
    },
  };

  const handleGetEmail = async () => {
    try {
      await getEmail(
        window.localStorage.getItem("workId"),
        setEmail,
        setNumQuestions
      );
    } catch (error) {
      toast({
        title: t("FEEDBACKS.GET_EMAIL_ERROR"),
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const handleUpdateEmail = async (isRestore = false) => {
    const newEmail = transformToVariablesToBack(
      tagifyRef.current.state.lastOriginalValueReported
    );
    if (isRestore) {
      setEmail("");
    }
    try {
      await updateEmail(
        window.localStorage.getItem("workId"),
        newEmail,
        isRestore,
        (value) => {
          if (isRestore) {
            setEmail(value);
          }
        }
      );
      if (!isRestore) {
        toast({
          title: t("FEEDBACKS.UPDATE_EMAIL"),
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      } else {
        toast({
          title: t("FEEDBACKS.RESTORED_EMAIL"),
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      }
    } catch (error) {
      toast({
        title: t("FEEDBACKS.UPDATE_EMAIL_ERROR"),
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const handleGetPreview = () => {
    setPreview("");
    try {
      previewEmail(window.localStorage.getItem("workId"), setPreview);
      setIsModalPreviewOpen(true);
    } catch (error) {
      toast({
        title: t("FEEDBACKS.PREVIEW_EMAIL_ERROR"),
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  };

  useEffect(() => {
    handleGetEmail();
  }, []);

  useEffect(() => {
    setEmail("");
    handleGetEmail();
  }, [window.localStorage.getItem("user-lang")]);

  return (
    <ContainerLayout>
      <Box width="100%" paddingLeft="70px">
        <Flex w="100%" justify="flex-start">
          <Button
            leftIcon={<ArrowBackIcon />}
            colorScheme="blue"
            variant="link"
            mb={2}
            onClick={() => {
              history.goBack();
            }}
          >
            {t("BACK")}
          </Button>
        </Flex>
        <Heading width="100%" size="lg" textAlign="left">
          {t("EDIT_EMAIL")}
        </Heading>
        <Text mt={6}>{t("EDIT_EMAIL_DESCRIPTION")}</Text>
        <Divider my={6} />
        <Skeleton isLoaded={!!email} height={300}>
          <Box padding={4} border="1px solid #E2E8F0" borderRadius={5}>
            <Flex justify="flex-end" gap={2} mb={2}>
              <IconButton
                aria-label={t("RESTORE")}
                icon={<FiRefreshCcw fontSize={20} />}
                colorScheme="blue"
                variant="ghost"
                size="lg"
                isRound={true}
                onClick={() => handleUpdateEmail(true)}
              />
              <IconButton
                aria-label={t("VIEW")}
                icon={<FaRegEye fontSize={20} />}
                colorScheme="blue"
                variant="ghost"
                size="lg"
                isRound={true}
                onClick={() => handleGetPreview()}
              />
              <Modal
                isOpen={isModalPreviewOpen}
                onClose={() => setIsModalPreviewOpen(false)}
                size="2xl"
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>{t("PREVIEW_EMAIL")}</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody pb={6}>
                    {preview ? (
                      <Text dangerouslySetInnerHTML={{ __html: preview }} />
                    ) : (
                      <Flex justify="center" align="center" height={300}>
                        <Spinner
                          thickness="4px"
                          speed="0.65s"
                          emptyColor="gray.200"
                          color="blue.500"
                          size="xl"
                        />
                      </Flex>
                    )}
                  </ModalBody>
                </ModalContent>
              </Modal>
              <IconButton
                aria-label={t("SAVE")}
                icon={<FiSave fontSize={20} />}
                colorScheme="blue"
                variant="ghost"
                size="lg"
                isRound={true}
                onClick={() => handleUpdateEmail()}
              />
            </Flex>
            <Tags
              settings={settings}
              value={transformToVariablesToFront(email)}
              InputMode="textarea"
              tagifyRef={tagifyRef}
            />
          </Box>
        </Skeleton>
      </Box>
    </ContainerLayout>
  );
};

export default EditEmail;
