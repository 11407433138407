import React from "react";

import {
  ButtonGroup,
  Button,
  Divider,
  Flex,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";

import { useHistory } from "react-router";

import { ClassMenu } from "../ClassMenu";
import StudentTag from "../StudentTag";
import ActivityCard from "../ActivityCard";

import { QUESTION } from "../../constants/Routes";
import { useNavigationCorrection } from "hooks/useNavigationCorrection";

const CompletedClassesList = (
  classesWithCompletedWorks,
  classUpdateCallback,
  mail
) => {
  let history = useHistory();
  const { setWorkId, setClassroomId } = useNavigationCorrection();

  return classesWithCompletedWorks?.map((aluneClass) => (
    <Flex marginTop="6" key={aluneClass.id} direction="column" mb="6">
      <Flex>
        <ButtonGroup
          size="md"
          isAttached
          variant={
            aluneClass.name === "Avaliação tutoria" ? "solid" : "outline"
          }
        >
          <Button p="1" isDisabled={true} _disabled={{ cursor: "default" }}>
            <StudentTag
              avatar=""
              name={aluneClass.name}
              description={aluneClass.name}
              didShowBackground={false}
            />
          </Button>
          <ClassMenu
            course_id={aluneClass.id}
            isClassroomTest={aluneClass.name === "Avaliação tutoria"}
            callback={classUpdateCallback}
          />
        </ButtonGroup>
      </Flex>
      <Divider marginBottom="4" marginTop="2" />
      <Wrap minchildwidth="350px" spacing={6}>
        {aluneClass.works.map((work) => (
          <WrapItem key={work.id}>
            <ActivityCard
              key={work.id}
              numAnswers={work.answers}
              activityName={work.title}
              activityDescription={work.description}
              activityDate={work.due_date}
              correctionPercentage={work.percentage_done}
              isClassroomTest={aluneClass.name === "Avaliação tutoria"}
              onClick={() => {
                setWorkId(work.id);
                setClassroomId(aluneClass.id);
                history.push({ pathname: QUESTION });
              }}
            />
          </WrapItem>
        ))}
      </Wrap>
    </Flex>
  ));
};

export default CompletedClassesList;
