import React from "react";
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { BrazilIcon } from "media/BrazilIcon";
import { USAIcon } from "media/USAIcon";
import { ChevronDownIcon } from "@chakra-ui/icons";

const ChangeLang = () => {
  const { i18n, t } = useTranslation();

  const handleClick = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("user-lang", lang);
  };

  return (
    <Menu>
      <MenuButton
        backgroundColor="#F7F7FE"
        mx={2}
        as={Button}
        rightIcon={<ChevronDownIcon />}
        p={2}
      >
        {localStorage.getItem("user-lang") === "en" ? (
          <USAIcon />
        ) : (
          <BrazilIcon />
        )}
      </MenuButton>
      <MenuList>
        <MenuItem onClick={() => handleClick("pt")}>
          <BrazilIcon /> <Text ml={2}>{t("PORTUGUESE")}</Text>
        </MenuItem>
        <MenuItem onClick={() => handleClick("en")}>
          <USAIcon /> <Text ml={2}>{t("ENGLISH")}</Text>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default ChangeLang;
