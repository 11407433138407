import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import * as ROUTES from "../constants/Routes";
import { post } from "../utils/fetch";

import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ContainerAuth from "components/ContainerAuth";
import { validateEmail } from "utils/validateData";

export default function ForgotPassword() {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const toast = useToast();
  const history = useHistory();

  async function requestPasswordChange(e) {
    e.preventDefault();
    try {
      setIsLoading(true);
      await post(
        "/api/auth/password_reset/",
        {
          email,
        },
        "POST"
      );
      setEmailSent(true);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast({
        description: t("FEEDBACKS.FORGOT_PASSWORD"),
        duration: 3000,
        status: "error",
        isClosable: true,
        position: "top",
      });
    }
  }

  const form = (
    <>
      <FormControl isInvalid={email && validateEmail(email)}>
        <FormLabel for="email">{t("EMAIL")}</FormLabel>
        <Input
          required
          type="email"
          name="email"
          placeholder="Ex.: tutoria@exemplo.com"
          onChange={(e) => setEmail(e.currentTarget.value)}
        />
        <FormErrorMessage>{t("FEEDBACKS.VALID_EMAIL")}</FormErrorMessage>
      </FormControl>

      <Button
        onClick={requestPasswordChange}
        isLoading={isLoading}
        colorScheme="red"
        w="100%"
        disabled={!email || validateEmail(email)}
      >
        {t("REQUEST_PASSWORD_CHANGE")}
      </Button>
      <Button
        onClick={() => history.push(ROUTES.LOGIN)}
        colorScheme="blue"
        variant="link"
      >
        {t("CANCEL")}
      </Button>
    </>
  );

  const message = (
    <>
      <Text width={350} textAlign="center">
        {t("REQUEST_PASSWORD_CHANGE_MESSAGE")}
      </Text>
      <Button colorScheme="red" w="100%" onClick={() => history.push("/")}>
        {t("RETURN_TO_LOGIN")}
      </Button>
    </>
  );

  return <ContainerAuth>{emailSent ? message : form}</ContainerAuth>;
}
