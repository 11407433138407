import { useEffect, useState } from "react";
import {
  Button,
  Select,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Input,
  VStack,
  Stack,
  Spacer,
  InputGroup,
  InputRightElement,
  IconButton,
  useToast,
} from "@chakra-ui/react";
import { useGoogleLogin } from "@react-oauth/google";
import { ChevronDownIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import moodle from "media/moodle_icon.png";
import { getMoodlePath } from "service/ReviewPageServices";
import { post } from "utils/fetch";
import { useTranslation } from "react-i18next";
import googleCircleIcon from "media/32x32_yellow_stroke_icon@1x.png";
import moodleCircleIcon from "media/moodle_circle_32.png";
import tutoriaCircleIcon from "media/favicon-32x32.png";

const ImportClassrooms = ({
  handleGoogle,
  classDataHandler,
  handleDefault,
}) => {
  const {
    isOpen: isOpenMoodle,
    onOpen: onOpenMoodle,
    onClose: onCloseMoodle,
  } = useDisclosure();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [institution, setInstitution] = useState();
  const [institutions, setInstitutions] = useState([]);
  const handleClick = () => setShow(!show);
  const toast = useToast();
  const { t } = useTranslation();
  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => handleGoogle(tokenResponse),
    scope:
      "https://www.googleapis.com/auth/classroom.courses.readonly https://www.googleapis.com/auth/classroom.coursework.me https://www.googleapis.com/auth/classroom.coursework.me.readonly https://www.googleapis.com/auth/classroom.coursework.students.readonly https://www.googleapis.com/auth/classroom.courses.readonly https://www.googleapis.com/auth/classroom.rosters.readonly https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/classroom.profile.emails https://www.googleapis.com/auth/classroom.profile.photos https://www.googleapis.com/auth/forms.responses.readonly https://www.googleapis.com/auth/forms.body.readonly",
    flow: "auth-code",
  });

  async function getMoodlePaths() {
    try {
      await getMoodlePath(setInstitutions);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getMoodlePaths();
  }, [isOpenMoodle]);

  return (
    <Menu>
      <MenuButton
        as={Button}
        colorScheme="blue"
        w={220}
        rightIcon={<ChevronDownIcon />}
      >
        {t("IMPORT_CLASSES")}
      </MenuButton>
      <MenuList>
        <MenuItem
          onClick={() => login()}
          style={{ padding: "8px 16px", fontWeight: 500 }}
        >
          <img
            src={googleCircleIcon}
            alt="Google Classroom Icon"
            style={{ marginRight: "16px" }}
          />{" "}
          Google Classroom
        </MenuItem>
        <MenuItem
          style={{ padding: "8px 16px", fontWeight: 500 }}
          onClick={onOpenMoodle}
        >
          <img
            src={moodleCircleIcon}
            alt="Moodle Icon"
            style={{ marginRight: "16px" }}
          />
          Moodle
        </MenuItem>
        <MenuItem
          style={{ padding: "8px 16px", fontWeight: 500 }}
          onClick={handleDefault}
        >
          <img
            src={tutoriaCircleIcon}
            alt="Tutoria Icon"
            style={{ marginRight: "16px" }}
          />
          Tutoria
        </MenuItem>
      </MenuList>
      <Modal isOpen={isOpenMoodle} onClose={onCloseMoodle}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <VStack spacing="5px" p="10">
              <img
                src={moodle}
                alt="Moodle icon"
                width={100}
                style={{ marginBottom: 20 }}
              />
              <Text fontSize="3xl">{t("LOGIN")}</Text>
              <Text fontSize="lg">{t("USE_MOODLE_ACCOUNT")}</Text>
            </VStack>

            <Stack style={{ margin: 30 }}>
              <Input
                placeholder={t("EMAIL")}
                focusBorderColor="orange.300"
                onChange={(e) => setEmail(e.target.value)}
              />
              <InputGroup size="md">
                <Input
                  type={show ? "text" : "password"}
                  placeholder={t("PASSWORD")}
                  focusBorderColor="orange.300"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <InputRightElement width="3rem">
                  <IconButton
                    colorScheme="orange"
                    aria-label={t("SHOW_PASSWORD")}
                    icon={show ? <ViewOffIcon /> : <ViewIcon />}
                    onClick={handleClick}
                    h="1.75rem"
                    size="sm"
                    focusBorderColor="orange.300"
                  />
                </InputRightElement>
              </InputGroup>
              <Select
                placeholder={t("SELECT_INSTITUTION")}
                focusBorderColor="orange.300"
                onChange={(e) => setInstitution(e.target.value)}
              >
                {institutions.map((item) => {
                  return (
                    <option value={item.id} key={item.id}>
                      {item.institution}
                    </option>
                  );
                })}
              </Select>
            </Stack>
          </ModalBody>

          <ModalFooter style={{ margin: 30 }}>
            <Button
              colorScheme="orange"
              variant="ghost"
              mr={3}
              onClick={onCloseMoodle}
            >
              {t("CANCEL")}
            </Button>
            <Spacer />
            <Button
              colorScheme="orange"
              isLoading={loading}
              isDisabled={!email || !password || !institution}
              onClick={async () => {
                try {
                  setLoading(true);
                  await post("api/auth/moodle/works/", {
                    login: email,
                    password: password,
                    institution_id: institution,
                  })
                    .then((data) => {
                      classDataHandler(data);
                      onCloseMoodle();
                      setLoading(false);
                    })
                    .catch((err) => {
                      console.log("Error: ", err);
                    });
                } catch (error) {
                  console.log(error);
                  setLoading(false);
                  toast({
                    title: t("FEEDBACKS.PASSWORD_OR_EMAIL"),
                    isClosable: true,
                    duration: 3500,
                    status: "error",
                  });
                }
              }}
            >
              {t("CONFIRM")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Menu>
  );
};

export default ImportClassrooms;
