// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  scroll-behavior: smooth;
}

.styles_feedback__question__6Aryg {
  background-color: #fafafa;
  border-radius: 30px;
  padding: 5px;
  align-items: center;
  font-size: 14px;
}
.styles_feedback__question__number__8woMr {
  background-color: #223401;
  color: white;
  border-radius: 50%;
  padding: 2px 4px;
  margin: 2px;
  font-size: 12px;
}
.styles_feedback__question__title__JZy2W {
  font-weight: 600;
  margin-left: 5px;
}
.styles_feedback__score__YIJVs {
  background-color: #fafafa;
  border-radius: 30px;
  padding: 0px 15px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  column-gap: 5px;
  font-size: 14px;
}
.styles_feedback__score__value__6c3SJ {
  color: #386ba6;
  font-weight: 600;
  font-size: 16px;
}

.styles_tableContainer__5UN\\+m {
  padding: 15px;
  background-color: #fafafa;
  margin-bottom: 20px;
  border-radius: 20px;
}
.styles_tableContainer__table__9t1fS {
  width: 100%;
}
.styles_tableContainer__table__tag__qTUeX {
  width: fit-content;
  border-radius: 20px;
  color: white;
  padding: 0 5px;
  margin: 5px;
}
.styles_tableContainer__table__9t1fS > tr > th {
  color: #7e7e7e;
  font-weight: 500;
  font-size: 14px;
  text-align: start;
}`, "",{"version":3,"sources":["webpack://./src/components/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;AACF;;AAGE;EACE,yBAAA;EACA,mBAAA;EACA,YAAA;EACA,mBAAA;EACA,eAAA;AAAJ;AAEI;EACE,yBAAA;EACA,YAAA;EACA,kBAAA;EACA,gBAAA;EACA,WAAA;EACA,eAAA;AAAN;AAGI;EACE,gBAAA;EACA,gBAAA;AADN;AAKE;EACE,yBAAA;EACA,mBAAA;EACA,iBAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;EACA,eAAA;AAHJ;AAKI;EACE,cAAA;EACA,gBAAA;EACA,eAAA;AAHN;;AAQA;EACE,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,mBAAA;AALF;AAOE;EACE,WAAA;AALJ;AAOI;EACE,kBAAA;EACA,mBAAA;EACA,YAAA;EACA,cAAA;EACA,WAAA;AALN;AASM;EACE,cAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AAPR","sourcesContent":["html {\n  scroll-behavior: smooth;\n}\n\n.feedback {\n  &__question {\n    background-color: #fafafa;\n    border-radius: 30px;\n    padding: 5px;\n    align-items: center;\n    font-size: 14px;\n\n    &__number {\n      background-color: #223401;\n      color: white;\n      border-radius: 50%;\n      padding: 2px 4px;\n      margin: 2px;\n      font-size: 12px;\n    }\n\n    &__title {\n      font-weight: 600;\n      margin-left: 5px;\n    }\n  }\n\n  &__score {\n    background-color: #fafafa;\n    border-radius: 30px;\n    padding: 0px 15px;\n    margin-left: 10px;\n    display: flex;\n    align-items: center;\n    column-gap: 5px;\n    font-size: 14px;\n\n    &__value {\n      color: #386ba6;\n      font-weight: 600;\n      font-size: 16px;\n    }\n  }\n}\n\n.tableContainer {\n  padding: 15px;\n  background-color: #fafafa;\n  margin-bottom: 20px;\n  border-radius: 20px;\n\n  &__table {\n    width: 100%;\n\n    &__tag {\n      width: fit-content;\n      border-radius: 20px;\n      color: white;\n      padding: 0 5px;\n      margin: 5px;\n    }\n\n    & > tr {\n      & > th {\n        color: #7e7e7e;\n        font-weight: 500;\n        font-size: 14px;\n        text-align: start;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"feedback__question": `styles_feedback__question__6Aryg`,
	"feedback__question__number": `styles_feedback__question__number__8woMr`,
	"feedback__question__title": `styles_feedback__question__title__JZy2W`,
	"feedback__score": `styles_feedback__score__YIJVs`,
	"feedback__score__value": `styles_feedback__score__value__6c3SJ`,
	"tableContainer": `styles_tableContainer__5UN+m`,
	"tableContainer__table": `styles_tableContainer__table__9t1fS`,
	"tableContainer__table__tag": `styles_tableContainer__table__tag__qTUeX`
};
export default ___CSS_LOADER_EXPORT___;
