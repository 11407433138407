import React, { useCallback, useState } from "react";
import * as ROUTES from "../constants/Routes";
import { Redirect } from "react-router-dom";
import { post } from "../utils/fetch";
import { loginSuccess, getUserLogin } from "../utils/user";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  useToast,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import ContainerAuth from "components/ContainerAuth";
import { useHistory } from "react-router-dom";
import HidePasswordButton from "components/HidePasswordButton";
import { validateEmail } from "utils/validateData";

export default function Login() {
  const [form, setForm] = useState({});
  const [loggedIn, setLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const toast = useToast();

  const errorMessages = useCallback(
    (erro) => {
      const errorMap = {
        "This field is required.": t("FEEDBACKS.FILL_ALL_FIELDS"),
        "This field may not be blank.": t("FEEDBACKS.FILL_ALL_FIELDS"),
        "Unable to log in with provided credentials.": t(
          "FEEDBACKS.PASSWORD_OR_EMAIL"
        ),
      };
      return errorMap[erro] || erro;
    },
    [t]
  );

  const changeForm = (e) => {
    const updatedForm = { ...form, [e.target.name]: e.target.value };
    setForm(updatedForm);
  };

  const sendForm = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const { token } = await post("/api/auth/login/", form);
      const success = await loginSuccess(token);
      if (success) {
        setLoggedIn(true);
      }
    } catch (error) {
      setIsLoading(false);
      toast({
        title: errorMessages(Object.values(error)[0].toString()),
        status: "error",
        duration: 3000,
        position: "top",
        isClosable: true,
      });
    }
  };

  if (loggedIn || getUserLogin()) {
    window.location.pathname = "/";
    return <Redirect to={"/"} />;
  }

  return (
    <ContainerAuth>
      <FormControl isInvalid={form.username && validateEmail(form.username)}>
        <FormLabel>{t("EMAIL")}</FormLabel>
        <Input
          type="email"
          name="username"
          placeholder="Ex.: tutoria@exemplo.com"
          required
          value={form.username}
          onChange={changeForm}
        />
        <FormErrorMessage>{t("FEEDBACKS.VALID_EMAIL")}</FormErrorMessage>
      </FormControl>

      <FormControl>
        <FormLabel>{t("PASSWORD")}</FormLabel>

        <InputGroup>
          <Input
            type={showPassword ? "text" : "password"}
            name="password"
            placeholder="********"
            required
            value={form.password}
            onChange={changeForm}
          />
          <HidePasswordButton
            showPassword={showPassword}
            setShowPassword={setShowPassword}
          />
        </InputGroup>
      </FormControl>

      <Button
        onClick={sendForm}
        isLoading={isLoading}
        colorScheme="red"
        w="100%"
        disabled={!form.username || !form.password}
      >
        {t("SIGN_IN")}
      </Button>

      <Flex direction="column" gap={2}>
        <Button
          onClick={() => history.push(ROUTES.REGISTER)}
          colorScheme="blue"
          variant="link"
        >
          {t("SIGN_UP")}
        </Button>
        <Button
          onClick={() => history.push(ROUTES.FORGOT_PASSWORD)}
          colorScheme="blue"
          variant="link"
        >
          {t("FORGOT_PASSWORD")}
        </Button>
      </Flex>
    </ContainerAuth>
  );
}
