import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { REVIEW_FEEDBACK } from "../constants/Routes";
import StudentTag from "./StudentTag";
import { useTranslation } from "react-i18next";
import { CheckCircleIcon } from "@chakra-ui/icons";
import styles from "./styles.module.scss";
import { useNavigationCorrection } from "hooks/useNavigationCorrection";
import QuestionTypes from "constants/QuestionTypes";
import { v4 as uuidv4 } from "uuid";

function TableTags({ tags }) {
  const { t } = useTranslation();
  return (
    <div className={styles.tableContainer}>
      {tags.length ? (
        <table className={styles.tableContainer__table}>
          <tr>
            <th>Tag</th>
            <th>{t("TYPE")}</th>
            <th>Feedback</th>
          </tr>
          {tags.map((tag) => {
            return (
              <tr key={uuidv4()}>
                <td style={{ width: "40%" }}>
                  <div
                    className={styles.tableContainer__table__tag}
                    style={{ backgroundColor: tag.tag.color }}
                  >
                    {tag.tag.tag_name}
                  </div>
                </td>
                <td style={{ width: "20%" }}>{t(tag.tag.type)}</td>
                <td style={{ width: "40%" }}>{tag.tag.explanation || "-"}</td>
              </tr>
            );
          })}
        </table>
      ) : (
        <span>{t("QUESTION_NOT_CORRECTED")}</span>
      )}
    </div>
  );
}
export const StudentAccordion = ({
  feedbackData = [],
  onSendStudentFeedbackHandler = () => {},
}) => {
  const [hasFeedbackSent, setHasFeedbackSent] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { response_id } = feedbackData;
  const history = useHistory();
  const { workId } = useNavigationCorrection();
  const { t } = useTranslation();

  return (
    <AccordionItem>
      {({ isExpanded }) => (
        <>
          <h2>
            <AccordionButton>
              <Flex flex="1" justifyContent="space-between" alignItems="center">
                <StudentTag
                  description={
                    feedbackData?.student_name || t("UNREGISTERED_STUDENT")
                  }
                  name={feedbackData?.student_name || t("UNREGISTERED_STUDENT")}
                />
                {feedbackData?.email_sent && (
                  <Text textAlign="center" mr="4" color="green.500">
                    <CheckCircleIcon /> {t("FEEDBACK_SENT")}
                  </Text>
                )}
              </Flex>

              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Box my="4" w="100%" justifyContent="flex-end">
              {feedbackData.feedback.map((item) => (
                <Flex flexDirection="column" key={uuidv4()}>
                  <Flex className={styles.feedback}>
                    <Flex className={styles.feedback__question}>
                      <Text className={styles.feedback__question__number}>
                        Q{item.question_number}
                      </Text>
                      <Text className={styles.feedback__question__title}>
                        {item.title}
                      </Text>
                    </Flex>
                    {item.max_points && (
                      <Text className={styles.feedback__score}>
                        {t("SCORE")}:
                        <Text className={styles.feedback__score__value}>
                          {" "}
                          {item.score !== null ? item.score : "-"}
                        </Text>
                      </Text>
                    )}
                  </Flex>
                  <Text mb={5} ml={2} mt={2}>
                    {item.answer}
                  </Text>
                  {item.question_type === QuestionTypes.PARAGRAPH_TEXT && (
                    <TableTags tags={item.tags} />
                  )}
                </Flex>
              ))}
              {hasFeedbackSent && (
                <Alert status="success" variant="subtle">
                  <AlertIcon />
                  {t("FEEDBACK_SENT")}
                </Alert>
              )}
              <Flex w="100%" justifyContent="flex-end">
                <Flex justifyContent="space-between">
                  <Button
                    size="sm"
                    mt="4"
                    mr={2}
                    onClick={() => setIsOpen(true)}
                  >
                    {t("PREVIEW_EMAIL")}
                  </Button>
                  <Modal
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                    size="2xl"
                  >
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>{t("PREVIEW_EMAIL")}</ModalHeader>
                      <ModalCloseButton />
                      <ModalBody pb={6}>
                        {feedbackData.feedback_msg ? (
                          <Text
                            dangerouslySetInnerHTML={{
                              __html: feedbackData.feedback_msg,
                            }}
                          />
                        ) : (
                          <Text>{t("FEEDBACK_PREVIEW_EMAIL_EMPTY")}</Text>
                        )}
                      </ModalBody>
                    </ModalContent>
                  </Modal>
                  <Button
                    size="sm"
                    mt="4"
                    mr={2}
                    onClick={() => {
                      history.push({
                        pathname: REVIEW_FEEDBACK,
                        state: {
                          workId: workId,
                          student_email: feedbackData.student_email,
                          form_id: feedbackData.form_id,
                          text: feedbackData.feedback_msg,
                          response_id,
                        },
                      });

                      history.go(1);
                    }}
                  >
                    {t("EDIT_STUDENT_FEEDBACK")}
                  </Button>
                  <Button
                    colorScheme="blue"
                    size="sm"
                    mt="4"
                    onClick={() =>
                      onSendStudentFeedbackHandler(
                        feedbackData,
                        setHasFeedbackSent
                      )
                    }
                  >
                    {t("SEND_INDIVIDUAL_FEEDBACK")}
                  </Button>
                </Flex>
              </Flex>
            </Box>
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
};
