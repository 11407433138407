import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { post } from "../utils/fetch";
import * as ROUTES from "../constants/Routes";
import { useTranslation } from "react-i18next";
import { useQuery } from "utils/useQuery";
import ContainerAuth from "components/ContainerAuth";
import { useHistory } from "react-router-dom";
import HidePasswordButton from "components/HidePasswordButton";
import { validateEmail } from "utils/validateData";
import ActiveAccount from "components/ActiveAccount";

export default function Register() {
  const { t } = useTranslation();
  const toast = useToast();
  const query = useQuery();
  const history = useHistory();
  const [form, setForm] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showEmailMessage, setShowEmailMessage] = useState(false);
  const [passwordError, setPasswordError] = useState("");

  const validatePasswordPolicy = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*]/.test(password);

    if (!password || password.length < minLength) {
      return t("PASSWORD_POLICY.LENGTH");
    } else if (!hasUpperCase) {
      return t("PASSWORD_POLICY.UPPERCASE");
    } else if (!hasLowerCase) {
      return t("PASSWORD_POLICY.LOWERCASE");
    } else if (!hasNumber) {
      return t("PASSWORD_POLICY.NUMBER");
    } else if (!hasSpecialChar) {
      return t("PASSWORD_POLICY.SPECIAL_CHAR");
    }
    return "";
  };

  const changeForm = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));

    if (name === "password") {
      const error = validatePasswordPolicy(value);
      setPasswordError(error);
    }
  };

  const handleAgreeWithPolicy = (e) => {
    const updatedForm = {
      ...form,
      [e.currentTarget.name]: e.currentTarget.checked,
    };
    setForm(updatedForm);
  };

  const sendForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await post("/api/auth/register/", {
        ...form,
        group: query.get("group") || "C",
      });
      setShowEmailMessage(true);
    } catch (error) {
      setIsLoading(false);
      error.errors?.map((item) => {
        return toast({
          title: t(item.code.toUpperCase()),
          position: "top",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
    }
  };

  const handleDisabledCreateAccount =
    !form.name ||
    validateEmail(form.email) ||
    !form.university ||
    !form.agreeWithPrivacy ||
    !form.password ||
    !form.passwordConfirm ||
    form.password !== form.passwordConfirm ||
    passwordError !== "";

  if (showEmailMessage) {
    return <ActiveAccount email={form.email} />;
  }

  return (
    <ContainerAuth>
      <FormControl>
        <FormLabel>{t("NAME")}</FormLabel>
        <Input
          type="text"
          name="name"
          placeholder="Ex.: Maria da Silva"
          required
          value={form.name}
          onChange={changeForm}
        />
      </FormControl>

      <FormControl isInvalid={form.email && validateEmail(form.email)}>
        <FormLabel>{t("EMAIL")}</FormLabel>
        <Input
          type="email"
          name="email"
          placeholder="Ex.: tutoria@exemplo.com"
          required
          value={form.email}
          onChange={changeForm}
        />
        <FormErrorMessage>{t("FEEDBACKS.VALID_EMAIL")}</FormErrorMessage>
      </FormControl>

      <FormControl>
        <FormLabel>{t("INSTITUTION")}</FormLabel>
        <Input
          type="text"
          name="university"
          placeholder="Ex.: ACME ltda"
          required
          value={form.university}
          onChange={changeForm}
        />
      </FormControl>

      <FormControl isInvalid={!!passwordError}>
        <FormLabel>{t("PASSWORD")}</FormLabel>
        <InputGroup>
          <Input
            type={showPassword ? "text" : "password"}
            name="password"
            placeholder="********"
            required
            value={form.password}
            onChange={changeForm}
          />
          <HidePasswordButton
            showPassword={showPassword}
            setShowPassword={setShowPassword}
          />
        </InputGroup>
        <FormErrorMessage>{passwordError}</FormErrorMessage>
      </FormControl>

      <FormControl
        isInvalid={
          form.password &&
          form.passwordConfirm &&
          form.password !== form.passwordConfirm
        }
      >
        <FormLabel>{t("CONFIRM_PASSWORD")}</FormLabel>
        <InputGroup>
          <Input
            type={showConfirmPassword ? "text" : "password"}
            name="passwordConfirm"
            placeholder="********"
            required
            value={form.passwordConfirm}
            onChange={changeForm}
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
          />
          <HidePasswordButton
            showPassword={showConfirmPassword}
            setShowPassword={setShowConfirmPassword}
          />
        </InputGroup>
        <FormErrorMessage>
          {t("FEEDBACKS.CONFIRM_PASSWORD_ERROR")}
        </FormErrorMessage>
      </FormControl>

      <Checkbox
        name="agreeWithPrivacy"
        required
        value={form.agreeWithPrivacy}
        onChange={handleAgreeWithPolicy}
      >
        {t("READ_AND_AGREE")}{" "}
        <Button
          variant="link"
          colorScheme="blue"
          onClick={() => history.push(ROUTES.PRIVACY_POLICY)}
        >
          {t("PRIVACY_POLICY")}
        </Button>{" "}
        {t("OF_TUTORIA")}
      </Checkbox>

      <Flex justify="space-between" w="100%">
        <Button
          onClick={() => history.push(ROUTES.LOGIN)}
          colorScheme="blue"
          variant="link"
        >
          {t("ALREADY_HAVE_ACCOUNT")}
        </Button>
        <Button
          isLoading={isLoading}
          colorScheme="red"
          onClick={sendForm}
          w={150}
          disabled={handleDisabledCreateAccount}
        >
          {t("CREATE_ACCOUNT")}
        </Button>
      </Flex>
    </ContainerAuth>
  );
}
