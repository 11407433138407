import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
} from "@chakra-ui/react";

import { useState } from "react";

import { post } from "../utils/fetch";
import { fetchUserData } from "../utils/user";

import { DeleteIcon, RepeatIcon } from "@chakra-ui/icons";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import { postSaveForms, postSaveSubmissions } from "service/ReviewPageServices";
import { useToast } from "@chakra-ui/react";

export const ClassMenu = ({ course_id, isClassroomTest, callback }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const toast = useToast();

  const handleUpdate = async () => {
    const { email: mail } = await fetchUserData();

    setLoading(true);

    try {
      await postSaveSubmissions(mail, course_id, () => {});
      await postSaveForms(mail, course_id, () => {});
      callback(course_id);
      toast({
        title: t("FEEDBACKS.UPDATE_CLASS_SUCCESS"),
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      toast({
        title: t("FEEDBACKS.UPDATE_CLASS_ERROR"),
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      const ret = await post("api/courses/", { course_id }, "DELETE");
      if (ret?.success) {
        callback(course_id);
        toast({
          title: t("FEEDBACKS.DELETE_CLASS_SUCCESS"),
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      }
    } catch (e) {
      toast({
        title: t("FEEDBACKS.DELETE_CLASS_ERROR"),
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  };

  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={loading ? <Spinner /> : <HiOutlineDotsVertical />}
          variant={isClassroomTest ? "solid" : "outline"}
          disabled={loading}
        />
        <MenuList>
          <MenuItem icon={<RepeatIcon />} onClick={handleUpdate}>
            {t("UPDATE_CLASS")}
          </MenuItem>
          <MenuItem icon={<DeleteIcon />} onClick={handleDelete}>
            {t("DELETE_CLASS")}
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};
