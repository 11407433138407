import { ViewOffIcon, ViewIcon } from "@chakra-ui/icons";
import { InputRightElement, IconButton } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

function HidePasswordButton({ showPassword, setShowPassword }) {
  const { t } = useTranslation();
  return (
    <InputRightElement width="3rem">
      <IconButton
        colorScheme="blue"
        aria-label={t("SHOW_PASSWORD")}
        icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
        onClick={() => {
          setShowPassword(!showPassword);
        }}
        h="1.75rem"
        size="sm"
        focusBorderColor="orange.300"
      />
    </InputRightElement>
  );
}

export default HidePasswordButton;
