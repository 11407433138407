import { get, post } from "utils/fetch";

export const getEmail = async (
  workId,
  callbackTemplate,
  callbackNumQuestions
) => {
  get(`/api/courses/correction/${workId}/template/`)
    .then((data) => {
      callbackTemplate(data.template);
      callbackNumQuestions(data.questions);
    })
    .catch((err) => console.log("Error: ", err));
};

export const updateEmail = async (
  workId,
  newEmail,
  isRestore = false,
  callback
) => {
  await post(
    `/api/courses/correction/${workId}/template/`,
    {
      template: newEmail,
      default: isRestore,
    },
    "PUT"
  )
    .then((data) => {
      callback(data.feedback_template);
    })
    .catch((err) => {
      console.log("Error: ", err);
    });
};

export const viewEmail = async (workId, callback) => {
  post(`/api/courses/correction/${workId}/template/preview`, "PATCH")
    .then((data) => {
      callback(data.template);
    })
    .catch((err) => {
      console.log("Error: ", err);
    });
};

export const previewEmail = async (workId, callback) => {
  post(`/api/courses/correction/feedback/`, { work_id: workId })
    .then((data) => {
      callback(data.data[0].feedback_msg);
    })
    .catch((err) => {
      console.log("Error: ", err);
    });
};
