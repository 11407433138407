import {
  Divider,
  Flex,
  Text,
  useDisclosure,
  useToast,
  Box,
} from "@chakra-ui/react";
import EvaluationScreenSkeleton from "components/EvaluationScreen/Skeleton/EvaluationScreenSkeleton";
import OnboardVideoModal from "components/OnboardVideoModal/OnboardVideoModal";
import {
  markerCreationVideo,
  markerReuse,
  plagiaryVideo,
} from "constants/OnboardVideoUrl";
import QuestionTypes from "constants/QuestionTypes";
import { genericErrorMessage } from "messages/errorMessages";
import { useContext, useEffect, useMemo, useState } from "react";
import { post } from "utils/fetch";
import { regexMarkFinder } from "utils/regexMarkFinder";
import { fetchUserData } from "utils/user";
import ContainerLayout from "../ContainerLayout";
import CorrectionProgress from "../CorrectionProgress";
import { EvaluationActions, EvaluationContext } from "./MyEvaluationContext";
import MyEvaluationGeneralComment from "./MyEvaluationGeneralComment";
import MyEvaluationMultipleChoice from "./MyEvaluationMultipleChoice";
import MyEvaluationNavigationButtons from "./MyEvaluationNavigationButtons";
import MyEvaluationParagraphText from "./MyEvaluationParagraphText";
import MyEvaluationScore from "./MyEvaluationScore";
import MyEvaluationTitle from "./MyEvaluationTitle";
import MyEvaluationTable from "./MyEvaluationTable";
import { useTranslation } from "react-i18next";
import { FaWandMagicSparkles } from "react-icons/fa6";
import { useNavigationCorrection } from "hooks/useNavigationCorrection";

const MyEvaluationContent = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [isUserOnboardEnabled] = useState(
    localStorage.getItem("user-onboarding-my-evaluation")
  );
  const { errorMessage, setRecommendedTags, dispatch } =
    useContext(EvaluationContext);
  const {
    responseId,
    questionId,
    workId,
    classroomId,
    setListEvaluation,
    setFilterBy,
    setQuestionType,
  } = useNavigationCorrection();
  const { formId, init, questionType, tags } = useContext(EvaluationContext);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    handleUserOnboardingVideo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserOnboardEnabled]);

  const handleUserOnboardingVideo = () => {
    if (isUserOnboardEnabled === null) {
      onOpen();
      localStorage.setItem("user-onboarding-my-evaluation", true);
    }
  };

  const handleSaveStartTime = () => {
    post(
      "api/courses/correction/",
      {
        question_id: questionId,
        answer_id: responseId,
        started_at: new Date(),
      },
      "PUT"
    );
  };

  const QuestionContent = useMemo(() => {
    const types = {
      [QuestionTypes.PARAGRAPH_TEXT]: MyEvaluationParagraphText,
      [QuestionTypes.TEXT]: MyEvaluationParagraphText,
      [QuestionTypes.MULTIPLE_CHOICE]: MyEvaluationMultipleChoice,
    };

    return types[questionType] || (() => <></>);
  }, [questionType]);

  useEffect(() => {
    if (errorMessage !== "") {
      if (!toast.isActive(classroomId)) {
        toast({
          classroomId,
          description: genericErrorMessage,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  useEffect(() => {
    post("api/courses/details/", {
      view: window.localStorage.getItem("viewOption"),
      work_id: workId,
    }).then((res) => {
      let data = res.data;
      setFilterBy(window.localStorage.getItem("viewOption"));
      setListEvaluation(data);
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    fetchUserData().then((r) => {
      post("api/courses/correction/", {
        response_id: responseId,
        question_id: questionId,
      }).then((res) => {
        if (res.data.recommended_tags) {
          res?.data?.recommended_tags?.forEach((tag) => {
            const initIndex = regexMarkFinder(
              tag.content_to_mark,
              res.data.answer
            );
            tag.init = initIndex || 0;
            tag.end = initIndex + tag.content_to_mark?.length || 0;
          });
        }
        init(r, res);
        setQuestionType(res.data.question_type);
        setIsLoading(false);
      });
    });
    handleSaveStartTime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [init, questionId, responseId]);

  const saveTagsForUser = (tagId, isAccepted) => {
    post("api/courses/correction/ai_tags", {
      question_id: questionId,
      answer_id: responseId,
      tag_id: tagId,
      accepted: isAccepted,
    });
  };

  if (isLoading)
    return (
      <ContainerLayout>
        <EvaluationScreenSkeleton />
      </ContainerLayout>
    );
  return (
    <Flex spacing="4" direction="row" marginLeft="40px">
      <OnboardVideoModal
        isOpen={isOpen}
        onClose={onClose}
        hasCarousel={true}
        videoList={[markerCreationVideo, markerReuse, plagiaryVideo]}
      />
      <ContainerLayout>
        <Flex justify="space-between" width="100%">
          <MyEvaluationTitle />
          <CorrectionProgress
            id={classroomId}
            activityId={workId}
            formId={formId}
            responseId={responseId}
            topN={2}
            percentageQuestions={50}
            percentageStudents={20}
            percentagePoints={70}
            position="sticky"
            marginTop={4}
          />
        </Flex>
        <Divider marginBottom="4" marginTop="2" borderColor="gray.400" />

        <Flex width="100%" justify="space-between">
          <QuestionContent />
          {questionType === QuestionTypes.PARAGRAPH_TEXT && (
            <Flex direction="column" width="28%">
              <Box className="box">
                <MyEvaluationScore />
                <MyEvaluationGeneralComment
                  responseID={responseId}
                  questionID={questionId}
                />
              </Box>

              <MyEvaluationTable
                title={
                  <Flex alignItems="center">
                    <Text mr={2}>{t("AI_SUGGESTION")}</Text>
                    <FaWandMagicSparkles />
                  </Flex>
                }
                tags={tags.filter((item) => item.isRecommendation)}
                removeFeedback={true}
                tooltipText={t("ACCEPT_SUGGESTION")}
                onAdd={(tag) => {
                  const payload = {
                    ...tag,
                    recommendation_accepted: true,
                  };
                  setRecommendedTags((state) =>
                    state.filter((r) => tag.id !== r.id)
                  );
                  dispatch({
                    type: EvaluationActions.addRange,
                    payload,
                  });
                }}
                onDelete={(tag) => {
                  setRecommendedTags((state) =>
                    state.filter((r) => tag.id !== r.id)
                  );
                  saveTagsForUser(tag.id, false);
                }}
                descWarning={t("AI_SUGGESTION_WARNING")}
              />
              <MyEvaluationNavigationButtons />
            </Flex>
          )}
        </Flex>
      </ContainerLayout>
    </Flex>
  );
};

export default MyEvaluationContent;
